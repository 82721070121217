<script>
import { Tooltip } from 'epmc-patterns/components/v2'
export default {
  components: { Tooltip },
}
</script>
<template>
  <span class="tipsTooltip tooltip">
    <tooltip max-width="595px">
      <template slot="trigger">
        <span class="seeTipsLabel"><i>(See tips).</i></span>
      </template>
      <p class="title">
        <b>Tips for using Boolean terms, <i>OR, AND,</i> and <i>NOT</i></b>
      </p>
      <div class="tip">
        <img src="@/assets/or-ellipse.png" />
        <p><span>OR</span> broadens a search, only one term must be present.</p>
      </div>
      <div class="tip">
        <img src="@/assets/and-ellipse.png" />
        <p><span>AND</span> narrows a search, both terms must be present.</p>
      </div>
      <div class="tip">
        <img src="@/assets/not-ellipse.png" />
        <p>
          <span>NOT</span> refines a search, one term must be present, while the
          other term is eliminated from the results.
        </p>
      </div>
    </tooltip>
  </span>
</template>

<style scoped lang="scss">
.tipsTooltip {
  .seeTipsLabel {
    color: $epmc-darker-blue;
    cursor: pointer;
  }
  .title {
    margin-bottom: $base-unit * 6;
  }
  .tip {
    display: flex;
    align-items: center;
    margin-bottom: $base-unit * 6;
    img {
      margin-right: $base-unit * 5;
    }
    display: flex;
    p {
      span {
        font-size: $base-unit * 5;
        font-weight: 600;
      }
    }
  }
}
</style>
